<script>
export default {
  name: 'ContentTypesActionOptions',
  data () {
    return {
      selectedContentTypeId: null,
      dialog: false,
      iconsMap: {
        course: 'mdi-laptop',
        video: 'mdi-play-circle-outline',
        article: 'mdi-text-box',
        podcast: 'mdi-microphone',
        slides: 'mdi-collage',
        book: 'mdi-book',
        event: 'mdi-calendar',
        questionnaire: 'mdi-list-status'
      }
    }
  },
  props: {
    contentTypes: {
      type: Array, default: () => []
    }
  },
  methods: {
    setSelectedContentTypeId (selectedContentTypeId) {
      this.selectedContentTypeId = selectedContentTypeId
      this.$emit('closeModalSelectAction', this.selectedContentTypeId)
      // const params = { contentTypeId: selectedContentTypeId, workspaceId: parseInt(this.$store.getters.getIndividualWorkspace.id) }
      // const routesToPush = [
      //   { dir: 'workspace.not.found', path: 'business.content.workspace.not.found.management', params },
      //   { dir: 'workspace.business', path: 'workspace.business.content.management', params },
      //   { dir: 'workspace.public', path: 'content.workspace.public.management', params },
      //   { dir: 'discovery.index', path: 'content.discovery.management', params },
      //   { dir: 'default', path: 'content.management', params }
      // ]
      // this.pushToRouteName(routesToPush)
    },
    clearSelectedContent () {
      this.selectedContentTypeId = null
      this.dialog = false
    }
  }
}
</script>

<template>
<div class="contents-options--container">
  <template v-for="content in contentTypes">
    <v-btn
 :key="content.id"
      @click="setSelectedContentTypeId(content.id)"
      outlined
      class="text-left my-1 content-option"
      color="#1200D3">
      <v-container>
        <v-icon class="mr-1">{{ iconsMap[content.alias] }}</v-icon> <span class="bold">{{$t(`content.type:${content.alias}`)}}</span>
      </v-container>
    </v-btn>
  </template>
</div>
</template>
<style lang="scss">
.contents-options--container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  .content-option {
    width: calc(50% - 12px);
    &:nth-child(2n) {
      margin-left: 24px;
    }
  }
}
@media only screen and (max-width: 768px) {
  .content-option {
    width: 100%;
  }
}
</style>
